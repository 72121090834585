:root {
  --fg: #0C0D0D;
  --bg: #ffffff;
  --accent: #C37898;

}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  color: var(--fg);
}

p {
  text-align: justify;
  text-justify: inter-word;

}

nav {
  height: 80px;
  background: var(--bg);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem calc((100vw - 1300px) / 2);
}

.logo {
  color: #000;
  font-size: 1.5rem;
  font-weight: bold;
  font-style: italic;
  padding: 0 2rem;
}

nav a {
  text-decoration: none;
  color: var(--fg);
  padding: 0 1.5rem;
  font-size: 1.2rem;
}

nav a:hover {
  color: var(--accent);
}

.main {
  background: var(--bg);
}

.main-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 95vh;
  padding: 3rem calc((100vw - 1300px) / 2);
}

.left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: var(--fg);
  padding: 0rem 2rem;
}

.left h1 {
  margin-bottom: 3rem;
  font-size: 3.4rem;
}

.left p {
  text-align: justify;
  text-justify: inter-word;
  margin-bottom: 3rem;
  margin-right: 1rem;
  font-size: 1.3rem;
  line-height: 2.0;
}

button {
  padding: 1rem 3rem;
  font-size: 1.2rem;
  border: 2px solid;
  border-color: var(--accent);
  color: var(--accent);
  background: var(--bg);
  cursor: pointer;
  border-radius: 50px;
}

button:hover {
  background: var(--accent);
  color: var(--bg);
  border-color: var(--accent);
}

.right {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0rem 0rem;
}

.image {
  width: 700px;
  height: 700px;
  object-fit: cover;
  object-position: 0 35%;
  border-radius: 50px;
  margin-right: 1rem;
}

@media screen and (max-width: 768px) {
  .main-container {
    grid-template-columns: 1fr;
  }
}
